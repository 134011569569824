import tableMutations from "@/store/templates/table/mutations";

export default {
  setInvoiceItemId(state, invoiceItemId) {
    state.invoiceItemId = invoiceItemId;
  },
  setCreditedInvoiceId(state, creditedInvoiceId) {
    state.creditedInvoiceId = creditedInvoiceId;
  },
  ...tableMutations
};
